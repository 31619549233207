import {filterGroupType} from '@/domain/services/Clas';

export const getUrlWithParamsFromObject = (baseUrl: string, filterGroup: filterGroupType) => {
  
  const filterGroupKeys = Object.keys(filterGroup);
  const lastIndex = filterGroupKeys.length -1;
  
  const filterString = filterGroupKeys.reduce(
    (acc: string, curr: string, currentIndex) => currentIndex === lastIndex ?
      acc + curr + '=' + filterGroup[curr as keyof filterGroupType] :
      acc + curr + '=' + filterGroup[curr as keyof filterGroupType] + '&', '');

  return filterString ? baseUrl+'?'+filterString : baseUrl;
  
};