import ClasImpl from '@/serviceImpl/ClasImpl';

import {ApiError, filteredParcelsType, filterGroupType} from '@/domain/services/Clas';

import getCredentialsUseCase from './getCredentialsUseCase';

const getFilteredParcelsUseCase = async (filterGroup: filterGroupType): Promise<filteredParcelsType | ApiError | void> => {

  const {accessToken} = getCredentialsUseCase();

  if (accessToken) {
    const filteredParcelIds = await ClasImpl.getFilteredParcels(filterGroup, accessToken);

    if('ids' in filteredParcelIds) {
      return Promise.resolve(filteredParcelIds);
    }
    if ('detail' in filteredParcelIds) {
      return Promise.reject(filteredParcelIds);
    }
  } else {
    return Promise.reject();
  }

};

export default getFilteredParcelsUseCase;