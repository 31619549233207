import {AnyLayer} from 'mapbox-gl';

const baseLayers = (profileUuid: string, filteredParcelIds: Array<number>): Array<AnyLayer> => ([
  {
    'id': 'baseParcelBorder',
    'type': 'line',
    'source': 'clas',
    'source-layer': 'api_parcel',
    'minzoom': 13,
    'filter': [
      'all',
      ['in', ['get', 'id'], ['literal', filteredParcelIds]],
      ['!=', ['get', 'account_id'], profileUuid]
    ],
    'paint': {
      'line-color': 'rgb(42,74,139)',
      'line-width': ['interpolate', ['linear'], ['zoom'],
        15, 0.5,
        20, 4
      ],
    }
  },
  {
    'id': 'baseParcelBackground',
    'type': 'fill',
    'source': 'clas',
    'source-layer': 'api_parcel',
    'filter': [
      'all',
      ['in', ['get', 'id'], ['literal', filteredParcelIds]],
      ['!=', ['get', 'account_id'], profileUuid]
    ],
    'paint': {
      'fill-color': 'rgb(42,74,139)',
      'fill-opacity': 0.3,
      'fill-outline-color': 'rgb(42,74,139)',
    }
  }
]);

const userLayers = (profileUuid: string, filteredParcelIds: Array<number>): Array<AnyLayer> => ([
  {
    'id': 'userParcelBorder',
    'type': 'line',
    'source': 'clas',
    'source-layer': 'api_parcel',
    'minzoom': 13,
    'filter': [
      'all',
      ['in', ['get', 'id'], ['literal', filteredParcelIds]],
      ['==', ['get', 'account_id'], profileUuid]
    ],
    'paint': {
      'line-color': 'rgba(230, 7, 7, 1)',
      'line-width': ['interpolate', ['linear'], ['zoom'],
        15, 0.5,
        20, 4
      ],
    }
  },
  {
    'id': 'userParcelBackground',
    'type': 'fill',
    'source': 'clas',
    'source-layer': 'api_parcel',
    'filter': [
      'all',
      ['in', ['get', 'id'], ['literal', filteredParcelIds]],
      ['==', ['get', 'account_id'], profileUuid]
    ],
    'paint': {
      'fill-color': 'rgba(230, 7, 7, 1)',
      'fill-opacity': 0.3,
      'fill-outline-color': 'rgba(255, 255, 255, 0)',
    }
  }
]);

const selectedLayers = (selectedParcelId: number): Array<AnyLayer> => ([
  {
    'id': 'selectedParcelBorder',
    'type': 'line',
    'source': 'clas',
    'source-layer': 'api_parcel',
    'minzoom': 13,
    'filter': ['==', ['get', 'id'], selectedParcelId],
    'paint': {
      'line-color': 'rgba(230, 7, 7, 1)',
      'line-width': ['interpolate', ['linear'], ['zoom'],
        15, 4,
        20, 4
      ],
    }
  },
  {
    'id': 'selectedParcelBackground',
    'type': 'fill',
    'source': 'clas',
    'source-layer': 'api_parcel',
    'filter': ['==', ['get', 'id'], selectedParcelId],
    'paint': {
      'fill-color': 'rgba(230, 7, 7, 1)',
      'fill-opacity': 0.3,
      'fill-outline-color': 'rgba(255, 255, 255, 0)',
    }
  }
]);

const layerConstructorUsers = (profileUuid: string | undefined, selectedParcelId: number | undefined, filteredParcelIds: Array<number>): Array<AnyLayer> => {

  const newLayers = [];

  if (profileUuid) {
    newLayers.push(
      ...baseLayers(profileUuid, filteredParcelIds),
      ...userLayers(profileUuid, filteredParcelIds)
    );
    if(selectedParcelId) {
      newLayers.push(
        ...selectedLayers(selectedParcelId)
      );
    }
  }

  return newLayers;

};



const layerConstructorRedNatura = (): Array<AnyLayer> => ([
  {
    'id': 'redNatura',
    'type': 'raster',
    'source': 'redNatura',
    'paint': {
      'raster-opacity': 0.5
    }
  }
]);

const layerConstructor = {
  users: layerConstructorUsers,
  redNatura: layerConstructorRedNatura
};
export default layerConstructor;