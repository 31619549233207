import React, {FC, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {FERTILIZATION, TYPES} from '@/config';
import {filterGroupType} from '@/domain/services/Clas';
import SelectInput from '@geomatico/geocomponents/Forms/SelectInput';
import Stack from '@mui/material/Stack';
import {IconButton} from '@mui/material';
import {formattedCrop, formattedMunicipality} from '@/domain/useCases/getManifestUseCase';
import {useTranslation} from 'react-i18next';

const containerSx: SxProps = {
  display: 'flex',
  flexDirection: 'column'
};

const menuSx: SxProps = {
  zIndex: 9000,
  top: 5,
  left: -5,
  height: '78vh'
};

export type FilterPanelProps = {
  crops: Array<formattedCrop> | undefined,
  municipalities: Array<formattedMunicipality> | undefined,
  onFilterChange: (filterGroup: filterGroupType) => void
}

const FilterPanel: FC<FilterPanelProps> = ({
  crops,
  municipalities,
  onFilterChange
}) => {
  const {t} = useTranslation();
  const [filterGroup, setFilterGroup] = useState<filterGroupType>({});

  const genericInputSx: SxProps = {
    '&.SelectInput-root': {//El selector
      width: '100%',
      my: 1
    },
  };

  const municipalityInputSx: SxProps = {
    ...genericInputSx,
    '& .SelectInput-select': {
      bgcolor: filterGroup.municipality ? 'primary.main' : undefined,
      color: filterGroup.municipality ? 'common.white' : undefined,
      fontWeight: 900
    },
    '& .MuiSvgIcon-root': {
      color: filterGroup.municipality ? 'common.white' : undefined,
    }
  };

  const cropInputSx: SxProps = {
    ...genericInputSx,
    '& .SelectInput-select': {
      bgcolor: filterGroup.crop ? 'primary.main' : undefined,
      color: filterGroup.crop ? 'common.white' : undefined,
      fontWeight: 900
    },
    '& .MuiSvgIcon-root': {
      color: filterGroup.crop ? 'common.white' : undefined,
    }
  };
  
  /*const useInputSx: SxProps = {
    ...genericInputSx,
    '& .SelectInput-select': {
      bgcolor: filterGroup.use ? 'primary.main' : undefined,
      color: filterGroup.use ? 'common.white' : undefined,
      fontWeight: 900
    },
    '& .MuiSvgIcon-root': {
      color: filterGroup.use ? 'common.white' : undefined,
    }
  };*/
  
  const typeInputSx: SxProps = {
    ...genericInputSx,
    '& .SelectInput-select': {
      bgcolor: filterGroup.type ? 'primary.main' : undefined,
      color: filterGroup.type ? 'common.white' : undefined,
      fontWeight: 900
    },
    '& .MuiSvgIcon-root': {
      color: filterGroup.type ? 'common.white' : undefined,
    }
  };
  
  const fertilizationInputSx: SxProps = {
    ...genericInputSx,
    '& .SelectInput-select': {
      bgcolor: filterGroup.fertilization ? 'primary.main' : undefined,
      color: filterGroup.fertilization ? 'common.white' : undefined,
      fontWeight: 900
    },
    '& .MuiSvgIcon-root': {
      color: filterGroup.fertilization ? 'common.white' : undefined,
    }
  };


  const handleFilterChange = (key: string, value: string | undefined) => {
    if(value === '') {
      const newFilterGroup = Object.keys(filterGroup)
        .filter(item => item !== key)
        .map(it => ({[it]: filterGroup[it as keyof filterGroupType]}))
        .reduce((acu, actual) => Object.assign(acu, actual), {});

      setFilterGroup(newFilterGroup);

    } else {
      setFilterGroup({
        ...filterGroup,
        [key]: value
      });
    }
  };

  const handleDeleteFilters = () => setFilterGroup({});

  useEffect(() => onFilterChange(filterGroup), [filterGroup]);

  

  return <Box sx={{containerSx}}>
    <Box display='flex' alignItems='center' justifyContent='space-between' my={1} sx={{gap: 1}}>
      <Stack flexDirection='row'>
        <FilterAltIcon color='primary'/>
        <Typography sx={{mt: 0.5, fontWeight: 'bold', color: 'primary.main'}}>FILTROS</Typography>
      </Stack>
      <IconButton disabled={Object.keys(filterGroup).length === 0} color='primary' onClick={handleDeleteFilters}>
        <FilterAltOffIcon/>
      </IconButton>
      

    </Box>
    {
      municipalities && <SelectInput options={municipalities}
        onOptionChange={(municipality) => handleFilterChange('municipality', municipality)}
        selectedOptionId={filterGroup.municipality || ''} allowEmptyValue={true} placeholderLabel={t('municipality')}
        sx={municipalityInputSx} menuSx={menuSx}/>
    }

    {
      crops && <SelectInput options={crops} onOptionChange={(crop) => handleFilterChange('crop', crop)}
        selectedOptionId={filterGroup.crop || ''} allowEmptyValue={true} placeholderLabel={t('crop')}
        sx={cropInputSx} menuSx={menuSx}/>
    }
 

    {/*<SelectInput options={USES} onOptionChange={(use) => handleFilterChange('use', use)}
      selectedOptionId={filterGroup.use} allowEmptyValue={true} placeholderLabel='USO' sx={useInputSx} menuSx={menuSx}/>*/}
    
    <SelectInput options={TYPES} onOptionChange={(type) => handleFilterChange('type', type)}
      selectedOptionId={filterGroup.type || ''} allowEmptyValue={true} placeholderLabel={t('type')}
      sx={typeInputSx} menuSx={menuSx}/>

    <SelectInput options={FERTILIZATION}
      onOptionChange={(fertilization) => handleFilterChange('fertilization', fertilization)}
      selectedOptionId={filterGroup.fertilization || ''} allowEmptyValue={true} placeholderLabel={t('fertilization')}
      sx={fertilizationInputSx} menuSx={menuSx}/>
 
    <Divider sx={{my: 2}}/>
  </Box>;
};

export default FilterPanel;