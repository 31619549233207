import {manifest} from '@/domain/services/Clas';

export const renameManifestKeysForInputComponent = (manifest: manifest) => {

  if (manifest) {
    return {
      municipalities: manifest.municipalities.map(item => ({
        label: item.label.toUpperCase(),
        id: item.value
      })),
      crops: manifest.crops.map(item => ({
        label: item.label.toUpperCase(),
        id: item.value
      })),
    };

  } else return {
    municipalities: [],
    crops: []
  };
};