import React, {FC, useEffect, useMemo, useRef, useState} from 'react';

//MUI
import Box from '@mui/material/Box';

//GEOCOMPONENTS
import Map from '@geomatico/geocomponents/Map/Map';

//GEPROCAR
import Sponsors from '../../components/Sponsors';
import layerConstructor from '../../components/utils/layers';
import sourcesConstructor from '../../components/utils/sources';

//UTILS
import {INITIAL_VIEWPORT, MAP_DETAILS} from '@/config';
import {AnyLayer, MapLayerMouseEvent, Style} from 'mapbox-gl';
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx';
import {Profile} from '@/domain/entities/Profile';
import {viewportType} from '@/domain/services/Clas';
import {bboxType} from '@/domain/services/Clas';
import type {MapRef} from 'react-map-gl';


const imgLegendSx: SxProps = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  m: 1,
  p: 1
};

export type MainContentProps = {
  filteredParcelIds: Array<number> | undefined,
  filteredParcelBbox: bboxType | null,
  mapStyle: Style | string,
  mapDetail: Array<string>
  profile: Profile | undefined,
  viewport: viewportType,
  onMapClick: (id: number) => void
  onViewportChange: (viewport: viewportType) => void
};

const MainContent: FC<MainContentProps> = ({
  filteredParcelIds,
  filteredParcelBbox,
  mapStyle,
  mapDetail,
  profile,
  viewport,
  onMapClick,
  onViewportChange
}) => {
  const [selectedParcelId, setSelectedParcelId] = useState(undefined);
  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    if(filteredParcelBbox) {
      const {xmin, ymin, xmax, ymax} = filteredParcelBbox;
      if (xmin && xmax && ymin && ymax) {
        mapRef.current?.fitBounds([[xmin, ymin], [xmax, ymax]],
          {
            duration: 2000,
            padding: {
              left: 50,
              right: 50,
              top: 50,
              bottom: 250
            }
          }
        );
      }
    }
  }, [filteredParcelBbox]);

  const detailLayers: Array<AnyLayer> = useMemo(() => {
    const layersResult = [];
    if (mapDetail.includes(MAP_DETAILS.REDNATURA)) {
      layersResult.push(...layerConstructor.redNatura());
    }
    return layersResult;
  }, [mapDetail]);

  const sources = useMemo(sourcesConstructor, []);

  const layers = useMemo(() => {
    if (filteredParcelIds) {
      return [
        ...detailLayers,
        ...layerConstructor.users(profile?.uuid, selectedParcelId, filteredParcelIds)
      ];
    }
  }, [detailLayers, profile, selectedParcelId, filteredParcelIds]);

  const handleMapClick = (e: MapLayerMouseEvent) => {
    const feature = e.features && e.features[0];

    onMapClick(feature?.properties?.id);
    setSelectedParcelId(feature?.properties?.id);
  };

  return <Map
    ref={mapRef}
    mapStyle={mapStyle}
    viewport={viewport}
    sources={sources}
    layers={layers}
    onViewportChange={onViewportChange}
    onClick={handleMapClick}
    interactiveLayerIds={['userParcelBackground']}
  >
    {
      mapDetail.includes(MAP_DETAILS.REDNATURA) &&
            <Box
              component='img'
              src='https://wms.mapama.gob.es/sig/Biodiversidad/RedNatura/Leyenda/RedNatura.png'
              sx={imgLegendSx}
            />
    }
    <Sponsors/>
  </Map>;
};

export default MainContent;
