import AuthImpl from '../../serviceImpl/AuthImpl';
import LocalStorageImpl from '../../serviceImpl/LocalStorageImpl';

const isLoggedUseCase = async (): Promise<boolean> => {

  const {accessToken, refreshToken} = LocalStorageImpl.getCredentials();

  if (accessToken && refreshToken) {
    return AuthImpl.verifyToken(accessToken)
      .then(() => {
        AuthImpl.refreshToken(refreshToken)
          .then((apiToken) => {
            if ('access' in apiToken) {
              LocalStorageImpl.setCredentials(apiToken.access, refreshToken);
            }
          });
        return Promise.resolve(true);
      })
      .catch(() => Promise.resolve(false));
  } else {
    return Promise.resolve(false);
  }
};

export default isLoggedUseCase;