import React, {FC} from 'react';
import {VegaLite} from 'react-vega';
import {TopLevelSpec} from 'vega-lite';

type dat = {
  type: string,
  value: number
}

export interface BarChartProps {
    data: Array<dat>,
}

const BarChart: FC<BarChartProps> = ({data}) => {

  const spec: TopLevelSpec  = {
    $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
    data: {
      values: data
    },
    mark: {
      type: 'bar',
      cornerRadiusEnd: 4
    },
    config: {
      background: undefined,
      axis: {
        domain: false,
        labelFontSize: 12,
        labelColor: '#363636',
        labelPadding: 10
      },
      axisX: {
        grid: false,
        ticks: false,
        title: null,
        labels: false
      },
      axisY: {
        grid: true,
        ticks: false,
        /* eslint-disable */ // esto no se puede quitar, porque no acepta que le ponga comillas simples
        labelExpr: "datum.value + ' (Ha)'",
        title: null
      }
    },
    view: {
      stroke: null
    },
    width: 300,
    encoding: {
      x: {
        field: 'type',
        type: 'nominal'
      },
      y: {
        field: 'value',
        type: 'quantitative'
      },
      color: {
        scale: {
          scheme: 'viridis',
        },
        field: 'type',
        type: 'nominal',
        legend: {
          title: '',
          symbolType: 'circle',
          rowPadding: 8,
          labelFontSize: 14,
          labelColor: '#363636',
          type: 'symbol',
          orient: 'bottom',
          columns: /*data.length < 7 ? data.length : data.length/2*/2
        }
      },
      tooltip: [
        {
          field: 'type',
          title: 'Tipo',
          type: 'nominal',
        },
        {
          field: 'value',
          title: 'Superficie (Ha)',
          type: 'quantitative'
        }
      ]
    }
  };




  return <>
    {
      data.length !== 0 && <VegaLite spec={spec} actions={false}/>
    }
  </>;
};

export default BarChart;