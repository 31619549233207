import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';

//GEPROCAR
import Cetemas from './logos/Cetemas';
import Cooperativas from './logos/Cooperativas';
import Sat from './logos/Sat';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';


const containerSx: SxProps = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '30%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: 5000
};

const Sponsors: FC = () => {
  
  return <Box
    sx={containerSx}>
    <Sat/>
    <Cooperativas/>
    <Cetemas/>
  </Box>;
};

export default Sponsors;
