import ClasImpl from '@/serviceImpl/ClasImpl';

import {Parcel} from '@/domain/entities/Parcel';
import {ApiError} from '@/domain/services/Clas';

import getCredentialsUseCase from './getCredentialsUseCase';

const getParcelsByIdUseCase = async (parcelId: number): Promise<Parcel | ApiError | void> => {
  
  const {accessToken} = getCredentialsUseCase();
  
  if (accessToken) {
    const parcel = await ClasImpl.getParcelById(parcelId, accessToken);
    
    if('id' in parcel && 'municipality' in parcel) {
      return Promise.resolve(parcel);
    }
    if ('detail' in parcel) {
      return Promise.reject(parcel);
    }
  } else {
    return Promise.reject();
  }
  
};

export default getParcelsByIdUseCase;