import Auth, {
  ApiToken,
  LoginError, RefreshToken,
  UserCreated,
  UserCreateParams,
  UserCreateParamsErrors
} from '../domain/services/Auth';

import {POST} from '@/serviceImpl/HttpFetch';

const API_URL = process.env.BASE_URL;

const login = async (email: string, password: string)=> 
  POST<Promise<ApiToken | LoginError>, {email: string, password: string}>(
    `${API_URL}/auth/jwt/create/`, 
    {email, password}
  );

const verifyToken = async (token: string)=>
  POST<Promise<void>, {token: string}>(
    `${API_URL}/auth/jwt/verify/`,
    {token}
  );

const refreshToken = async (refresh: string)=>
  POST<Promise<RefreshToken | LoginError>, {refresh: string}>(
    `${API_URL}/auth/jwt/refresh/`,
    {refresh}
  );

const activateUser = async (uid: string, token: string)=>
  POST<Promise<void>, {uid: string, token: string}>(
    `${API_URL}/auth/users/activation/`,
    {uid, token}
  );

const singup = async (userParams: UserCreateParams)=>
  POST<Promise<UserCreated | UserCreateParamsErrors>, UserCreateParams>(
    `${API_URL}/auth/users/`,
    {...userParams}
  );

const AuthImpl: Auth = {
  login,
  verifyToken,
  refreshToken,
  activateUser,
  singup,
};

export default AuthImpl;