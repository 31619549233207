import ClasImpl from '@/serviceImpl/ClasImpl';

import {ApiError, DashboardData, filterGroupType} from '@/domain/services/Clas';

import getCredentialsUseCase from './getCredentialsUseCase';


const getDashboardDataUseCase = async (filterGroup: filterGroupType): Promise<DashboardData | ApiError | void> => {
  
  const {accessToken} = getCredentialsUseCase();
  

  if (accessToken) {
    const profile = await ClasImpl.profile(accessToken);
    if('uuid' in profile){
      const data = await ClasImpl.getDashboardData(profile?.uuid, filterGroup, accessToken);

      if('use' in data) {
        return Promise.resolve(data);
      }
      if ('detail' in data) {
        return Promise.reject(data);
      }
    } else {
      return Promise.reject();
    }
  }
    

};

export default getDashboardDataUseCase;