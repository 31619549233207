import React, {FC, useMemo} from 'react';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import Box from '@mui/material/Box';
import ChartCard from '@/components/Charts/ChartCard';
import PieChart from '@/components/Charts/PieChart';
import BarChart from '@/components/Charts/BarChart';
import {DashboardData} from '@/domain/services/Clas';
import {useTranslation} from 'react-i18next';
import formatFertilizationUseCase from '@/domain/useCases/formatFertilizationUseCase';
import {FERTILIZATION_COLORS} from '@/config';

const containerSx: SxProps = {
  display: 'flex',
  flexFlow: 'row wrap',
  gap: 2,
  m:2
};

export interface DashboardProps {
  data: DashboardData | undefined
}

const DashboardView: FC<DashboardProps> = ({data}) => {
  const {t} = useTranslation();
  
  const dataFertilization = useMemo(() => formatFertilizationUseCase(data), [data]);

  return <Box sx={containerSx}>
    {
      data && <>
        <ChartCard title={t('dashboard.areaByType')} type='piechart'>
          <BarChart data={data?.type}/>
        </ChartCard>
        <ChartCard title={t('dashboard.areaByCrop')} type='barchart'>
          <BarChart data={data?.crop}/>
        </ChartCard>
        {
          dataFertilization && <ChartCard title={t('dashboard.areaByFertilization')} type='piechart'>
            <PieChart data={dataFertilization} colors={FERTILIZATION_COLORS}/>
          </ChartCard>
        }
      </>
    }
  </Box>;
};

export default DashboardView;