import {DashboardData} from '@/domain/services/Clas';

const formatFertilizationUseCase = (data: DashboardData| undefined) => {
  const hasFertilization = data?.fertilization.some(item => item.type);
  const hasNotFertilization = data?.fertilization.some(item => !item.type);

  const fertilizationValue = hasFertilization ? data?.fertilization.find(item => item.type)?.value.toFixed(2) || 0 : 0;
  const notFertilizationValue = hasNotFertilization ? data?.fertilization.find(item => !item.type)?.value.toFixed(2) || 0 : 0;

  return [
    { type: 'Fertilizado', value: fertilizationValue },
    { type: 'No Fertilizado', value: notFertilizationValue }
  ];
};

export default formatFertilizationUseCase;