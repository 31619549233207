import ClasImpl from '@/serviceImpl/ClasImpl';

import {ApiError, manifest} from '@/domain/services/Clas';

import getCredentialsUseCase from './getCredentialsUseCase';

export type formattedMunicipality = {
  id: string,
  label: string
}

export type formattedCrop = {
  id: string,
  label: string
}

export type formattedManifest = {
  municipalities: Array<formattedMunicipality>
  crops: Array<formattedCrop>
}

const getManifestUseCase = async (): Promise<manifest | ApiError | void> => {

  const {accessToken} = getCredentialsUseCase();

  if (accessToken) {
    const manifest = await ClasImpl.manifest(accessToken);

    if('municipalities' in manifest) {
      return Promise.resolve(manifest);
    }
    if ('detail' in manifest) {
      return Promise.reject(manifest);
    }
  } else {
    return Promise.reject();
  }

};

export default getManifestUseCase;