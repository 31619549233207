import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

//MUI ICONS
import PersonIcon from '@mui/icons-material/Person';

//GEOCOMPONENTS
import BaseMapList from '@geomatico/geocomponents/Map/BaseMapList';
import LayerSwitcher from '@geomatico/geocomponents/Map/LayerSwitcher';

//CLAS
import DialogPanel from '../common/DialogPanel';
import StepSlider from '../StepSlider';
import LogoHorizontalColor from '../logos/LogoHorizontalColor';

//UTILS
import {useTranslation} from 'react-i18next';
import {MAP_DETAIL_STYLES, MAPSTYLES, PNOA_HISTORICO_ID} from '../../config';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';

const layerSwitcherStyle = {
  '&.LayerSwitcher-root': {
    justifyContent: 'flex-start',
  }
};

const baseMapListSx: SxProps = {
  '&.LayerSwitcher-root': {
    justifyContent: 'flex-start',
    mt: 1
  },
};

const linkSx = {
  mt: 1,
  mb: 0,
  ml: 'auto',
  mr: 1
};

const dialogPanelSx: SxProps = {
  '&.DialogPanel-root': {
    height: '800px'
  }
};

export interface NavigationMenuProps {
  isNavigationMenuOpen: boolean,
  mapStyleId: string,
  mapDetail: Array<string>,
  PNOASelectedYear: number,
  profile: {
    first_name: string,
    last_name: string,
    email: string
  } | undefined,
  onClose: () => void,
  onMapDetailChange: (detail: Array<string>) => void
  onPNOAYearChange: (value: number) => void,
  onStyleChange: (mapStyleId: string) => void,
}

const THUMBNAIL_MEASURE = 40;

const NavigationMenu: FC<NavigationMenuProps> = ({
  isNavigationMenuOpen,
  mapDetail,
  mapStyleId,
  PNOASelectedYear,
  profile,
  onClose,
  onMapDetailChange,
  onPNOAYearChange,
  onStyleChange,
}) => {
  
  const {t} = useTranslation();
  
  const handleNavigationMenuClose = () => onClose && onClose();

  return <DialogPanel
    isOpen={isNavigationMenuOpen}
    onClose={handleNavigationMenuClose}
    label={t('navigationMenu.title')}
    fullWidth
    sx={dialogPanelSx}
  >
    <Box sx={{display: 'flex', flexDirection: 'column', m: 0}}>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', my: 2}}>
        <Box sx={{display: 'flex'}}>
          <PersonIcon fontSize="medium" color='primary' sx={{mr: 1, pb: 0.25}}/>
          <Typography color='primary' sx={{fontWeight: 900, textTransform: 'uppercase'}}>{profile?.first_name} {profile?.last_name}</Typography>
        </Box>
        <Typography sx={{color: 'grey.600', ml: 4}}>{profile?.email}</Typography>
      </Box>
      <Divider/>
      <Typography variant='label' sx={{mt: 1.5}}>{t('baseMapStyle.title')}</Typography>
      <BaseMapList
        styles={MAPSTYLES}
        selectedStyleId={mapStyleId}
        onStyleChange={onStyleChange}
        thumbnailWidth={THUMBNAIL_MEASURE}
        thumbnailHeight={THUMBNAIL_MEASURE}
        sx={baseMapListSx}
      />
      {
        mapStyleId === PNOA_HISTORICO_ID ? <StepSlider PNOASelectedYear={PNOASelectedYear} onPNOAYearChange={onPNOAYearChange}/> : undefined
      }
      <Divider/>
      <Typography variant='label' sx={{mt: 1.5}}>{t('conditions')}</Typography>
      <LayerSwitcher
        layers={MAP_DETAIL_STYLES}
        selectedLayerIds={mapDetail}
        exclusive={false}
        allowEmptyValue={true}
        onLayerChange={onMapDetailChange}
        itemWidth={72}
        thumbnailWidth={THUMBNAIL_MEASURE}
        thumbnailHeight={THUMBNAIL_MEASURE}
        sx={layerSwitcherStyle}
      />
      <Divider/>
    </Box>
    <Box sx={{display: 'flex', flexDirection: 'row', my: 1}}>
      <Chip label={
        <>
          <Typography variant='caption' sx={{fontWeight: 'bolder'}}>{t('navigationMenu.version')}: </Typography>

          <Typography variant='caption' sx={{mr: 1}}>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              VERSION
            }
          </Typography>
          <Typography variant='caption' sx={{fontWeight: 'bolder'}}>{t('navigationMenu.hash')}: </Typography>
          <Typography variant='caption'>
            {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              HASH
            }
          </Typography>
        </>
      } size='small' sx={{mt: 1}}/>
      <Link sx={linkSx} href="https://geomatico.es" target="_blank">
        <LogoHorizontalColor width={80}/>
      </Link>
    </Box>
    
  </DialogPanel>;
};

export default NavigationMenu;