import ClasImpl from '@/serviceImpl/ClasImpl';

import {Profile} from '@/domain/entities/Profile';
import {ApiError} from '@/domain/services/Clas';

import getCredentialsUseCase from './getCredentialsUseCase';

const getProfileUseCase = async (): Promise<Profile | ApiError | void> => {

  const {accessToken} = getCredentialsUseCase();

  if (accessToken) {
    const profile = await ClasImpl.profile(accessToken);
    if ('uuid' in profile) {
      return Promise.resolve(profile);
    }
    if ('detail' in profile) {
      return Promise.reject(profile);
    }
  } else {
    return Promise.reject();
  }

};

export default getProfileUseCase;