import React, {FC, useMemo, useState} from 'react';

//MUI
import Box from '@mui/material/Box';
import ResponsiveHeader from '@geomatico/geocomponents/Layout/ResponsiveHeader';
import SidePanel from '@geomatico/geocomponents/Layout/SidePanel';
import MiniSidePanel from '@geomatico/geocomponents/Layout/MiniSidePanel';

//MUI-ICONS
import MapIcon from '@mui/icons-material/Map';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';

//GEPROCAR
import GeprocarIsotipoBlanco from './logos/GeprocarIsotipoBlanco';
import NavigationMenu from './NavigationMenu/NavigationMenu';

//UTILS
import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ACTIONS_ID,
  DRAWER_WIDTH,
  MINI_SIDE_PANEL_DENSE_WIDTH,
  MINI_SIDE_PANEL_WIDTH,
  SM_BREAKPOINT
} from '@/config';
import {Profile} from '@/domain/entities/Profile';

export type MainProps = {
  widescreen: boolean,
  isSidePanelOpen: boolean
}

const Main = styled(Box)<MainProps>(() => ({
  flexGrow: 1,
  padding: 0,
  position: 'absolute',
  top: 56,
  '@media (min-width: 0px) and (orientation: landscape)': {
    top: 48
  },
  ['@media (min-width: '+ SM_BREAKPOINT +'px)']: {
    top: 64
  },
  bottom: 0,
  right: 0,
  left: MINI_SIDE_PANEL_WIDTH + DRAWER_WIDTH
})) as React.ElementType;

const responsiveHeaderSx = {
  '&.MuiAppBar-root': {
    zIndex: 1500
  },
  '& .ResponsiveHeader-title': {
    fontSize: '32px',
    fontWeight: 'bolder'
  }
};

export type LayoutProps = {
  actionId: string,
  mainContent: React.ReactElement,
  mapDetail: Array<string>,
  mapStyleId: string,
  miniSidePanelSelectedActionId?: string,
  PNOASelectedYear: number,
  profile: Profile | undefined,
  sidePanelContent: React.ReactElement,
  onActionIdChange: (action: string) => void,
  onMapDetailChange: (detail: Array<string>) => void,
  onMapStyleChange: (mapStyleId: string) => void,
  onPNOAYearChange: (value: number) => void
};

const Layout:  FC<LayoutProps> = ({
  actionId,
  mainContent,
  mapDetail,
  mapStyleId,
  miniSidePanelSelectedActionId= 'mapView',
  PNOASelectedYear,
  profile,
  sidePanelContent,
  onActionIdChange,
  onMapDetailChange,
  onMapStyleChange,
  onPNOAYearChange
}) => {
  const widescreen = useMediaQuery(`@media (min-width:${SM_BREAKPOINT}px)`, {noSsr: true});
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [isNavigationMenuOpen, setNavigationMenuOpen] = useState(false);

  const sidePanelSx = {
    '& .MuiPaper-root': {
      left: widescreen ? MINI_SIDE_PANEL_WIDTH : MINI_SIDE_PANEL_DENSE_WIDTH
    }
  };

  //LAYOUT
  const handleClose = () => setIsSidePanelOpen(!isSidePanelOpen);
  const handleNavigationOpen = () => setNavigationMenuOpen(true);
  const handleNavigationMenuClose = () => setNavigationMenuOpen(!isNavigationMenuOpen);

  const handleMiniSidePanelActionClick = (actionId: string) => {
    if ([ACTIONS_ID.MAP, ACTIONS_ID.DASHBOARD].includes(actionId)){
      onActionIdChange(actionId);
    }
  };

  const ACTIONS = useMemo(() =>
    [
      {
        id: actionId === ACTIONS_ID.MAP ? ACTIONS_ID.DASHBOARD : ACTIONS_ID.MAP,
        visible: true,
        label: actionId === ACTIONS_ID.MAP ? 'Dashboard' : 'Map',
        content: actionId === ACTIONS_ID.MAP ? <DashboardIcon/> : <MapIcon/>,
        disabled: false,
        action: actionId === ACTIONS_ID.MAP ?
          () => onActionIdChange(ACTIONS_ID.DASHBOARD) :
          () => onActionIdChange(ACTIONS_ID.MAP)
      },
    ], [actionId]);

  const PROFILE_DEFAULT: Profile = {
    first_name: '',
    last_name: '',
    uuid: '',
    email: '',
    nif: '',
    phone: '',
    address: ''
  };

  const PROFILE = profile || PROFILE_DEFAULT;

  return <>
    <ResponsiveHeader
      title='Geprocar'
      logo={ <GeprocarIsotipoBlanco style={{margin: '0 20px'}}/> }
      startIcon={<MenuIcon/>}
      onStartIconClick={widescreen ? handleNavigationOpen : handleClose}
      isStartIconCloseable={false}
      sx={responsiveHeaderSx}
    >
    </ResponsiveHeader>
    <MiniSidePanel
      actions={ACTIONS}
      selectedActionId={miniSidePanelSelectedActionId}
      onActionClick={handleMiniSidePanelActionClick}
      dense={!widescreen}
    />
    {sidePanelContent && isSidePanelOpen &&
      <SidePanel
        drawerWidth={DRAWER_WIDTH + 'px'}
        anchor="left"
        isOpen={isSidePanelOpen}
        onClose={handleClose}
        widescreen={widescreen}
        sx={sidePanelSx}
      >
        {sidePanelContent}
      </SidePanel>
    }
    <Main widescreen={widescreen} isSidePanelOpen={isSidePanelOpen}>
      {mainContent}
    </Main>
    <NavigationMenu
      isNavigationMenuOpen={isNavigationMenuOpen}
      mapStyleId={mapStyleId}
      mapDetail={mapDetail}
      PNOASelectedYear={PNOASelectedYear}
      profile={PROFILE}
      onClose={handleNavigationMenuClose}
      onMapDetailChange={onMapDetailChange}
      onPNOAYearChange={onPNOAYearChange}
      onStyleChange={onMapStyleChange}
    />
  </>;
};

export default Layout;
