import {Sources} from 'mapbox-gl';


const sourcesConstructor: () => Sources = () => ({
  clas: {
    type: 'vector',
    scheme: 'tms',
    tiles: [
      'https://clas.geomatico.es/geoserver/gwc/service/tms/1.0.0/clas:api_parcel@MVT@pbf/{z}/{x}/{y}.pbf'
    ]
  },
  redNatura: {
    type: 'raster',
    tiles: [
      'https://wms.mapama.gob.es/sig/Biodiversidad/RedNatura/wms.aspx?bbox={bbox-epsg-3857}&styles=default&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=PS.ProtectedSite'
    ],
    bounds: [-7.1191,42.8477,-4.4934,43.75],
    minzoom: 7,
    maxzoom: 14
  }
});

export default sourcesConstructor;