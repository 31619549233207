import ClasImpl from '@/serviceImpl/ClasImpl';

import {ApiError} from '@/domain/services/Clas';
import {Parcel} from '@/domain/entities/Parcel';

import getCredentialsUseCase from './getCredentialsUseCase';

const editNewParcelUseCase = async (newParcel: Parcel): Promise<Parcel | ApiError | void> => {

  const {accessToken} = getCredentialsUseCase();

  if (accessToken) {
    const newParcelInfo = await ClasImpl.editParcel(newParcel, accessToken);

    if('id' in newParcelInfo && 'municipality' in newParcelInfo) {
      return Promise.resolve(newParcelInfo);
    }
    if ('detail' in newParcelInfo) {
      return Promise.reject(newParcelInfo);
    }
  } else {
    return Promise.reject();
  }

};

export default editNewParcelUseCase;