import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

//UTILS
import grey from '@mui/material/colors/grey';
import {PNOA_YEARS} from '../config';
import {PNOA_MAX, PNOA_MIN} from '../utils/pnoa';
import {useTranslation} from 'react-i18next';

const slider = {
  color: 'primary.main',
  '& .MuiSlider-rail': {
    height: 6,
    opacity: 1,
    backgroundColor: 'primary.main',
  },
  '& .MuiSlider-thumb': {
    backgroundColor: 'secondary.main',
    width: 14,
    height: 14
  },
  '& .MuiSlider-markLabel': {
    color: grey[900],
    fontSize: '10px'

  },
};

export type StepSliderProps = {
  PNOASelectedYear: number,
  onPNOAYearChange: (PNOASelectedYear: number) => void,
}

const StepSlider: FC<StepSliderProps> = ({PNOASelectedYear, onPNOAYearChange}) => {

  const {t} = useTranslation();

  const handleChange = (event: Event, value: number | number[]): void => {
    onPNOAYearChange(value as number);
  };
  
  return <>
    <Box sx={{mt: 0, mb: 1, mx: 1}}>
      <Typography component='h6' variant='body1' noWrap sx={{color: 'grey.500'}}>{t('baseMapStyle.chooseYear')}</Typography>
    </Box>
    <Box sx={{my: 0, mx: 1}}>
      <Slider
        step={null}
        min={PNOA_MIN}
        max={PNOA_MAX}
        defaultValue={PNOA_MAX}
        onChange={handleChange}
        color='secondary'
        marks={PNOA_YEARS}
        value={PNOASelectedYear}
        sx={slider}
      />
    </Box>
  </>;
};

export default StepSlider;