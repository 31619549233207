import React, {FC} from 'react';

//MUI
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';

//CLAS
import LoginWrapper from './LoginWrapper';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import {useNavigate} from 'react-router-dom';

const textField = {
  mb: 1,
  height: '64px',
  p: 0,
  '& .MuiInputBase-root': {
    color: '#5F5D70',
    fontWeight: '900',
    backgroundColor: 'grey.100'
  }
};
const errorText = {
  color: 'error.main',
  mb: 2,
  ml: 2
};
const loginButton = {
  borderRadius: 0,
  height:'48px',
};


export type LoginFormProps = {
  loginErrorMessage?: string,
  username: string,
  password: string,
  onUsernameChanged: (name: string) => void,
  onPasswordChanged: (password: string) => void,
  onLoginButtonClicked: () => void,
}

const LoginForm: FC<LoginFormProps> = ({loginErrorMessage, username, password, onUsernameChanged, onPasswordChanged, onLoginButtonClicked}) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => onUsernameChanged(e.target.value);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => onPasswordChanged(e.target.value);
  const handleLoginClick = (e: React.FormEvent) => {
    e.preventDefault();
    onLoginButtonClicked();
  };

  const handleGoToSignUpClick = () => navigate(`/${i18n.resolvedLanguage}/signup`);
  
  return <LoginWrapper>

    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
      <LockIcon/>
    </Avatar>
    <Typography component='h1' variant='h5'>{t('login.enter')}</Typography>
    <Box component="form" onSubmit={handleLoginClick} noValidate sx={{ mt: 12 }}>
      <TextField
        type='text'
        margin="normal"
        required
        fullWidth
        id="email"
        name="email"
        autoComplete="email"
        autoFocus
        placeholder='Email'
        sx={textField}
        onChange={handleNameChange}
        value={username}
        InputProps={{ startAdornment: <AccountCircleIcon sx={{marginRight: 1}} /> }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        type="password"
        id="password"
        autoComplete="current-password"
        placeholder='Contraseña'
        sx={textField}
        onChange={handlePasswordChange}
        value={password}
        InputProps={{ startAdornment: <LockIcon sx={{marginRight: 1}} /> }}
      />
      <Typography variant='body2' sx={errorText}>{loginErrorMessage}</Typography>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, ...loginButton}}>
        {t('login.enter')}
      </Button>
      <Grid container>
        <Grid item>
          <Link onClick={handleGoToSignUpClick} variant="body2" sx={{cursor: 'pointer'}}>
            {t('login.withoutAccount')}
          </Link>
        </Grid>
      </Grid>
    </Box>
    
  </LoginWrapper>;
};

export default LoginForm;