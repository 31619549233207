import React, {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import loginUseCase from '@/domain/useCases/loginUseCase';
import i18n from 'i18next';
import LoginForm from '../components/Login/Login';
import isLoggedUseCase from '@/domain/useCases/isLoggedUseCase';

const LoginView: FC = () => {
  const navigate = useNavigate();

  const [user, setUsername] = useState('');
  const [pass, setPassword] = useState('');
  const [errors, setErrors] = useState('');

  useEffect(() => {
    isLoggedUseCase()
      .then((isLogged) => isLogged ? navigate(`/${i18n.resolvedLanguage}/`) : undefined);
  }, []);

  const handleLogin = () => {
    loginUseCase(user, pass)
      .then((result) => {
        if (result) {
          setErrors(result.detail);
        } else {
          navigate(`/${i18n.resolvedLanguage}/`);
        }
      });
  };

  return <LoginForm
    password={pass}
    username={user}
    loginErrorMessage={errors}
    onLoginButtonClicked={handleLogin}
    onPasswordChanged={setPassword}
    onUsernameChanged={setUsername}
  />;
};

export default LoginView;