import React, {useMemo, useState} from 'react';

//CLAS
import Layout from '../../components/Layout';
import SidePanelContent from './SidePanelContent';
import MainContent from './MainContent';
import DashboardView from './DashboardView';

//UTILS

import {ACTIONS_ID, INITIAL_VIEWPORT, PNOA_HISTORICO_ID, PNOA_DEFAULT_YEAR, INITIAL_MAPSTYLE} from '@/config';

import {PNOA_HISTORICO_BASEMAP} from '@/utils/pnoa';
import {useAsync} from 'react-use';
import {Style} from 'mapbox-gl';
import {Profile} from '@/domain/entities/Profile';
import {Parcel} from '@/domain/entities/Parcel';
import {DashboardData} from '@/domain/services/Clas';

import {bboxType, filterGroupType, manifest} from '@/domain/services/Clas';

import getDashboardDataUseCase from '@/domain/useCases/getDashboardDataUseCase';
import getFilteredParcelsUseCase from '@/domain/useCases/getFilteredParcelsUseCase';
import getProfileUseCase from '@/domain/useCases/getProfileUseCase';
import getParcelsByIdUseCase from '@/domain/useCases/getParcelsByIdUseCase';
import editNewParcelUseCase from '@/domain/useCases/editParcelUseCase';
import getManifestUseCase from '@/domain/useCases/getManifestUseCase';

const Index = () => {
  const [mapStyleId, setMapStyleId] = useState<string>(INITIAL_MAPSTYLE.id);
  const [PNOASelectedYear, setPNOASelectedYear] = useState<number>(PNOA_DEFAULT_YEAR);
  const [mapDetail, setMapDetail] = useState<Array<string | never>>([]);
  const [viewport, setViewport] = useState(INITIAL_VIEWPORT);

  //MANIFEST
  const {value: manifest} = useAsync(getManifestUseCase, []);
  const manifestValue = useMemo<manifest | undefined>(() => {
    if( manifest && 'municipalities' in manifest ) return manifest;
    return undefined;
  }, [manifest]);

  //PROFILE
  const profile = useAsync(getProfileUseCase, []);
  const profileValue = useMemo<Profile | undefined>(() => {
    if( profile.value && 'uuid' in profile.value ) return profile.value;
    return undefined;
  }, [profile]);

  //GET PARCEL BY ID
  const [selectedParcelId, setSelectedParcelId] = useState<number | undefined>(undefined);

  const handleSelectedParcelIdChange = (id: number | undefined) => setSelectedParcelId(id);

  const {value: selectedParcel} = useAsync(async () =>
    selectedParcelId ? getParcelsByIdUseCase(selectedParcelId) : Promise.resolve(), [selectedParcelId]);

  const selectedParcelValue = useMemo<Parcel | undefined>(() => {
    if( selectedParcel && 'id' in selectedParcel ) return selectedParcel;
    return undefined;
  }, [selectedParcel]);

  //EDIT PARCEL INFO
  const [newParcelInfo, setNewParcelInfo] = useState<Parcel | undefined>(selectedParcelValue);
  const {value: newParcel} = useAsync(async () =>
    newParcelInfo ? editNewParcelUseCase(newParcelInfo) : Promise.resolve(), [newParcelInfo]);

  const handleActionIdChange = (actionId: string) => {
    setActionId(actionId);
    if(actionId !== ACTIONS_ID.MAP) {
      handleSelectedParcelIdChange(undefined);
    }
  };

  const handleInfoChange = (newParcel: Parcel) => {
    setNewParcelInfo(newParcel);
    handleSelectedParcelIdChange(newParcel.id);
  };

  const handleMapStyleChange = (mapStyleId: string) => setMapStyleId(mapStyleId);

  const mapStyle = useMemo<Style | string>(() => {
    if (mapStyleId !== PNOA_HISTORICO_ID) {
      return mapStyleId;
    }
    if (PNOASelectedYear) {
      return PNOA_HISTORICO_BASEMAP(PNOASelectedYear) as Style;
    }
    return mapStyleId;
  }, [mapStyleId, PNOASelectedYear]);

  const handlePNOAYearChange = (year: number) => setPNOASelectedYear(year);

  const handleMapDetailChange = (newMapDetail: Array<string | never>) => newMapDetail !== mapDetail && setMapDetail(newMapDetail);

  //FILTERS------------------------------
  const [filterGroup, setFilterGroup] = useState<filterGroupType>({});
  const handleFilterChange = (filterGroup: filterGroupType) => setFilterGroup(filterGroup);
  const {value: filteredParcels} = useAsync(async () =>
    filterGroup ? getFilteredParcelsUseCase(filterGroup) : Promise.resolve(), [filterGroup]);

  const filteredParcelIds = useMemo<Array<number> | undefined>(() => {
    if( filteredParcels && 'ids' in filteredParcels ) return filteredParcels.ids;
    return [];
  }, [filteredParcels]);

  const filteredParcelBbox = useMemo<bboxType | null>(() => {
    if( filteredParcels && 'bbox' in filteredParcels ) return filteredParcels.bbox;
    return null;
  }, [filteredParcels]);

  //DASHBOARD----------------------------------------------------------------------------------------------------------
  const [actionId, setActionId] = useState(ACTIONS_ID.MAP);
  const {value: data} = useAsync(async () =>
    actionId === ACTIONS_ID.DASHBOARD ? getDashboardDataUseCase(filterGroup) : Promise.resolve(), [actionId, filterGroup]);

  const dataValue = useMemo<DashboardData | undefined>(() => {
    if( data && 'use' in data ) return data;
    return undefined;
  }, [data]);

  const sidePanelContent = <SidePanelContent
    manifest={manifestValue}
    selectedParcel={selectedParcelValue}
    profile={profileValue}
    onInfoChange={handleInfoChange}
    onFilterChange={handleFilterChange}
  />;

  const mainContent = actionId === ACTIONS_ID.MAP ?
    <MainContent
      filteredParcelBbox={filteredParcelBbox}
      filteredParcelIds={filteredParcelIds}
      profile={profileValue}
      mapStyle={mapStyle}
      mapDetail={mapDetail}
      viewport={viewport}
      onMapClick={handleSelectedParcelIdChange}
      onViewportChange={setViewport}
    /> :
    <DashboardView data={dataValue}/>;
  console.log('selectedParcelValue', selectedParcelValue);
  return <Layout
    profile={profileValue}
    mapDetail={mapDetail}
    mapStyleId={mapStyleId}
    PNOASelectedYear={PNOASelectedYear}
    actionId={actionId}
    sidePanelContent={sidePanelContent}
    mainContent={mainContent}
    onActionIdChange={handleActionIdChange}
    onMapStyleChange={handleMapStyleChange}
    onMapDetailChange={handleMapDetailChange}
    onPNOAYearChange={handlePNOAYearChange}
  />;
};

export default Index;
