import React, {FC} from 'react';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
/*import ListItemText from '@mui/material/ListItemText';
import {grey} from '@mui/material/colors';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';*/

const textFieldNoEditableSx = {
  borderRadius: '4px',
  flexGrow: 1,
  fontSize: '0.875rem',
  fontWeight: 900,
  '& .MuiInputBase-input': {
    borderRadius: '4px',
    fontSize: '0.875rem',
    width: '268px'
  },
  '.MuiSelect-select': {
    color: 'primary.main'
  },
  '.MuiOutlinedInput-notchedOutline': {
    /*border: 0,*/
    borderColor: 'primary.main',
    borderWidth: 2,
    borderRadius: '4px'
  }
};

const textFieldEditableSx = {
  borderRadius: '4px',
  flex: 1,
  fontSize: '0.875rem',
  '& .MuiInputBase-input': {
    fontSize: '0.875rem'
  }
};

//FILTRADO
/*const selectSx: SxProps = {
  '&.MuiOutlinedInput-root': {//El input sin el menu desplegado
    bgcolor: 'white',
    color: '#757575',
    height: 40

  },
  '& .MuiSvgIcon-root': {//El icono del desplegable
    color: 'grey[400]'
  },
};*/

/*const menuItem = {
  color: 'text.secondary',
  borderBottom: `1px solid ${grey[200]}`,
  padding: '0px 0px 0px 4px',
  height: 35,
};

const listItemText = {
  padding: '0px 16px 0px 8px',
};*/

/*const MenuProps = {
  getcontentanchorel: null,
  PaperProps: {
    style: {
      marginTop: 4,
      marginLeft: -14,
      borderRadius: 5,
    }
  },
  MenuListProps: {
    style: {
      padding: 0,
    }
  }
};*/


type item = {
  id: string | number,
  label: string
}

export interface TextInputProps {
  isEditing: boolean,
  items: Array<item>
  id: string,
  onChange: (id: string) => void
}

const SelectInput: FC<TextInputProps> = ({
  isEditing,
  items,
  id,
  onChange
}) => {

  const handlechange = (e: SelectChangeEvent) => onChange(e.target.value);

  return isEditing ?
    <FormControl fullWidth sx={{minWidth: 100}}>
      <Select size='small' value={id} onChange={handlechange} sx={textFieldEditableSx}
      /*sx={selectSx}*/
      /*MenuProps={MenuProps}*/
      >
        {
          items.map((item, index) => <MenuItem
            sx={{fontSize: '0.875rem', py: 0, fontWeight: 900}}
            key={index} value={item.id}>
            {item.label}
          </MenuItem>)
        }

        {/*{
          values.map(({id, label}) => (
            <MenuItem key={id} value={id} sx={menuItem}>
              <ListItemText primary={label} sx={listItemText}/>
            </MenuItem>
          ))
        }*/}
      </Select>
    </FormControl>
    : <FormControl sx={{ bgcolor: 'primary.lighterest', borderRadius: '4px', minWidth: 100, boxShadow: 2 }} fullWidth>
      <Select size='small' value={id} sx={textFieldNoEditableSx} readOnly
        IconComponent={() => <ArrowDropDown style={{ display: 'none' }} />}
      >
        {
          items.map(item => <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>)
        }
        {/*{
          items.map(({id, label}) => (
            <MenuItem key={id} value={id} sx={menuItem}>
              <ListItemText primary={label} sx={listItemText}/>
            </MenuItem>
          ))
        }*/}
      </Select>
    </FormControl>;
};

export default SelectInput;