import React, {FC, useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import BungalowIcon from '@mui/icons-material/Bungalow';

import SelectInput from '../common/SelectInput';

import ButtonGroup from '@geomatico/geocomponents/Forms/ButtonGroup';

import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {primaryColor} from '@/theme';
import {TYPES, USES} from '@/config';
import {useTranslation} from 'react-i18next';
import {Parcel} from '@/domain/entities/Parcel';
import {Profile} from '@/domain/entities/Profile';
import {formattedCrop} from '@/domain/useCases/getManifestUseCase';

const containerSx: SxProps = {
  display: 'flex',
  flexDirection: 'column'
};

const userSx: SxProps = {
  color: 'primary.main',
  fontWeight: 700,
  py: 1
};

const wrapperMunicipalitySx: SxProps = {
  display: 'flex',
  alignItems: 'flex-end',
  mr: 0,
  mb: 1
};

const municipalitySx: SxProps = {
  color: 'grey.700',
  fontWeight: 700,
  ml: 1
};

const wrapperRefSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};

const titleSx: SxProps = {
  color: 'grey.400',
  minWidth: 100,
  fontWeight: 700
};

const wrapperAreafSx: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const editableFieldSx: SxProps = {
  display: 'flex',
  flex: 'row wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  my: 1,
  gap: 1
};

const buttonGroupSx: SxProps = {
  '&.ButtonGroup-root': {
    maxHeight: 32,
    boxShadow: 0
  },
  '& .ButtonGroup-button': {
    minWidth: 50,
    p: 0
  },
  
};

const buttonGroupNoEditableSx: SxProps = {
  '&.ButtonGroup-root': {
    maxHeight: 32
  },
  '& .ButtonGroup-button': {
    minWidth: 50,
    p: 0
  }
};

const buttonContainerSx: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: 3
};

const buttonSx: SxProps = {
  '&.Mui-disabled': {
    bgcolor: 'grey.300',
    color: 'common.white',
    fontWeight: 800
  }
};

export interface InfoPanelProps {
  crops: Array<formattedCrop> | undefined,
  parcel: Parcel,
  profile: Profile,
  onChange: (newParcel: Parcel) => void
}

const InfoPanel: FC<InfoPanelProps> = ({
  crops,
  parcel,
  profile,
  onChange
}) => {

  const {t} = useTranslation();
  
  const [isEditing, setEditing] = useState(false);
  const [newInfo, setNewInfo] = useState(parcel);

  useEffect(() => setNewInfo(parcel), [parcel]);

  const handleEditClick = () => {
    setEditing(!isEditing);
    if(isEditing){
      setNewInfo(parcel);
    }
  };
  
  const handleSaveClick = () => {
    if(newInfo) {
      onChange(newInfo);
    }
    setNewInfo(newInfo);
    setEditing(!isEditing);
  };
  
  const handlePropertyChange = (property: { [key: string]: string | boolean}) => {
    setNewInfo(
      {
        ...newInfo,
        ...property
      }
    );
  };

  return <Box sx={containerSx}>
    <Typography variant='h6' sx={userSx}>{profile.first_name.toUpperCase()} {profile.last_name.toUpperCase()}</Typography>
    <Box sx={wrapperMunicipalitySx}>
      <BungalowIcon sx={{color: 'grey.500'}}/>
      <Typography variant='body1' sx={municipalitySx}>{newInfo.municipality}</Typography>
    </Box>
    <Box sx={wrapperAreafSx}>
      <Typography variant='body1' sx={titleSx}>{t('infoPanel.area').toUpperCase()}</Typography>
      <Typography variant='body1' sx={municipalitySx}>{newInfo.area.toFixed(2)} Ha</Typography>
    </Box>
    <Box sx={wrapperRefSx}>
      <Typography variant='caption' sx={titleSx}>{t('infoPanel.refcat').toUpperCase()}</Typography>
      <Typography variant='caption' sx={municipalitySx}>{newInfo.refcat || '-'}</Typography>
    </Box>
    <Box sx={wrapperRefSx}>
      <Typography variant='caption' sx={titleSx}>{t('infoPanel.sigpac').toUpperCase()}</Typography>
      <Typography variant='caption' sx={municipalitySx}>{newInfo.sigpac || '-'}</Typography>
    </Box>

    <Divider sx={{my: 2}}/>
    <Box sx={editableFieldSx}>
      <Typography variant='body1' sx={titleSx}>{t('infoPanel.fertilization').toUpperCase()}</Typography>
      {
        isEditing ?
          <ButtonGroup sx={buttonGroupSx}
            variant='outlined'
            color={primaryColor}
            items={[
              {id: 'true', content: <p>{t('yes').toUpperCase()}</p>},
              {id: 'false', content: <p>{t('no').toUpperCase()}</p>}
            ]}
            selectedItemId={newInfo?.fertilization.toString()}
            onItemClick={(fertilization) => handlePropertyChange({fertilization: fertilization !== 'false'})}
          /> :
          <ButtonGroup sx={buttonGroupNoEditableSx}
            variant='outlined'
            color={primaryColor}
            items={[newInfo.fertilization ? {id: 'true', content: <p>{t('yes').toUpperCase()}</p>} : {id: 'false', content: <p>{t('no').toUpperCase()}</p>}]}
            selectedItemId={newInfo.fertilization.toString()}
            onItemClick={(fertilization) => handlePropertyChange({fertilization: Boolean(fertilization)})}
          />
      }
    </Box>
    {
      crops && <Box sx={editableFieldSx}>
        <Typography variant="overline" sx={titleSx}>{t('infoPanel.crop').toUpperCase()}</Typography>
        <SelectInput isEditing={isEditing} items={crops} id={newInfo.crop || ''}
          onChange={(crop) => handlePropertyChange({crop})}/>
      </Box>
    }

    <Box sx={editableFieldSx}>
      <Typography variant='overline' sx={titleSx}>{t('infoPanel.use').toUpperCase()}</Typography>
      <SelectInput isEditing={isEditing} items={USES} id={newInfo.use || ''} onChange={(use) => handlePropertyChange({use})}/>
    </Box>

    <Box sx={editableFieldSx}>
      <Typography variant='overline' sx={titleSx}>{t('infoPanel.type').toUpperCase()}</Typography>
      <SelectInput isEditing={isEditing} items={TYPES} id={newInfo.type || ''} onChange={(type) => handlePropertyChange({type})}/>
    </Box>

    <Box sx={buttonContainerSx}>
      <Button variant={isEditing ? 'outlined' : 'contained'} onClick={handleEditClick} sx={{width: 100}}>{isEditing ? t('cancel') : t('edit')}</Button>
      <Button variant={!isEditing ? 'outlined' : 'contained'} onClick={handleSaveClick} disabled={!isEditing} sx={buttonSx}>{t('save')}</Button>
    </Box>

  </Box>;
};

export default InfoPanel;