import createTheme from '@mui/material/styles/createTheme';
import {lighten} from '@mui/system/colorManipulator';
import {PaletteMode} from '@mui/material';

export const primaryColor = '#50A684';
const secondaryColor = '#91D6AC';

const theme = (mode?: PaletteMode) => createTheme({
  palette: {
    mode: mode ? mode : 'light',
    primary: {
      main: primaryColor,
      lighterest: '#e7f3ea',
      lighter: '#cfe7d6',
      light: '#b8dcc2',
      contrastText: '#fff',
    },
    secondary: {
      main: secondaryColor,
      contrastText: primaryColor,
    },
    text: {
      primary: '#252424',
      secondary: '#746F81',
    }
  },
  typography: {
    label: {
      fontWeight: 900,
      paddingRight: 1
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          label: 'body1',
        }
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        /* Firefox Scrollbar */
        'body': {
          scrollbarColor: `${lighten(primaryColor, 0.5)} transparent`, /* scroll thumb and track */
          scrollbarWidth: '2px'
        },
        /* Chrome Scrollbar */
        '*::-webkit-scrollbar': {
          width: '0.2em',
          height: '0.15em'
        },
        '*::-webkit-scrollbar-track': {
          WebkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: lighten(primaryColor, 0.5),
          opacity: 0.2,
          outline: '0px solid slategrey'
        }
      }
    }
  }
});

export default theme;
