import React, {FC, ReactElement} from 'react';
import {Card} from '@mui/material';
import Typography from '@mui/material/Typography';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import Box from '@mui/material/Box';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';

const titleSx: SxProps = {
  fontWeight: 700,
};

const iconSx: SxProps = {
  color:'primary.main',
  mb: 0.5,
  mr: 2
};

export interface ChartCardProps {
  title: string
  type: 'piechart' | 'barchart'
  children: ReactElement
}

const ChartCard: FC<ChartCardProps> = ({title, children, type}) => {
  return <Card sx={{bgcolor: 'ghostwhite', flexFlow: 'column no-wrap', justifyContent: 'center', alignItems: 'center', p: 2, width: 'auto', flex: '1 0 30%'}}>
    <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', mb: 2}}>
      {
        type === 'piechart' ? <PieChartIcon sx={iconSx}/> : <BarChartIcon sx={iconSx}/>
      }
      <Typography variant='subtitle1' color='primary.main' sx={titleSx} align='center'>{title.toUpperCase()}</Typography>
    </Box>
    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      {children}
    </Box>
  </Card>;
};

export default ChartCard;