import React, {FC, useMemo} from 'react';

//MUI
import Stack from '@mui/material/Stack';

//CLAS
import Logos from '@/components/Logos';
import InfoPanel from '@/components/InfoPanel/InfoPanel';

//UTILS
import {Parcel} from '@/domain/entities/Parcel';
import {Profile} from '@/domain/entities/Profile';
import FilterPanel from '@/components/FilterPanel/FilterPanel';
import {filterGroupType, manifest} from '@/domain/services/Clas';
import {renameManifestKeysForInputComponent} from '@/domain/useCases/renameManifestKeysForInputComponent';

const stackSx = {
  height: '100%',
  overflow: 'hidden'
};

export type SidePanelContentProps = {
  manifest: manifest | undefined,
  selectedParcel?: Parcel | undefined,
  profile?: Profile | undefined,
  onInfoChange: (newParcel: Parcel) => void,
  onFilterChange: (filterGroup: filterGroupType) => void
}

const SidePanelContent: FC<SidePanelContentProps> = ({
  manifest,
  selectedParcel,
  profile,
  onFilterChange,
  onInfoChange
}) => {

  const municipalities = useMemo(() => {
    return manifest && renameManifestKeysForInputComponent(manifest)?.municipalities.sort((a, b) => a.label.localeCompare(b.label));
  }, [manifest]);

  const crops = useMemo(() => {
    return manifest && renameManifestKeysForInputComponent(manifest)?.crops.sort((a, b) => a.label.localeCompare(b.label));
  }, [manifest]);

  return <Stack sx={stackSx}>
    <FilterPanel onFilterChange={onFilterChange} municipalities={municipalities} crops={crops}/>
    { selectedParcel && profile ?
      <InfoPanel crops={crops} parcel={selectedParcel} profile={profile} onChange={onInfoChange}/>
      : undefined
    }
    <Logos/>
  </Stack>;
};

export default SidePanelContent;
