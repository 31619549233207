import React, {FC} from 'react';
import {VegaLite} from 'react-vega';
import {TopLevelSpec} from 'vega-lite';

type dat = {
  type: string,
  value: number | string
}

type color = {
  type: string,
  value: string
}

export interface PieChartProps {
  data: Array<dat>,
  colors: Array<color>
}

const PieChart: FC<PieChartProps> = ({data, colors}) => {
  console.log('data', data);
  const categories = colors.map(color => color.type);
  const colorValues = colors.map(color => color.value);
  
  const spec: TopLevelSpec  = {
    $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
    data: {
      values: data
    },
    mark: {
      type: 'arc',
      outerRadius: 100,
      innerRadius: 45,
    },
    config: {
      background: undefined
    },
    encoding: {
      theta: {
        field: 'value',
        type: 'quantitative'
      },
      color: {
        scale: {
          domain: categories,
          range: colorValues
        },
        field: 'type',
        type: 'nominal',
        legend: {
          title: '',
          rowPadding: 8,
          labelFontSize: 14,
          labelColor: '#363636',
          type: 'symbol',
          orient: 'bottom',
          columns: 2
        }
      },
      tooltip: [
        {
          field: 'type',
          title: 'Tipo',
          type: 'nominal',
        },
        {
          field: 'value',
          title: 'Superficie (Ha)',
          type: 'quantitative'
        }
      ]
    }
  };

  
  return <>
    {
      data.length !== 0 && <VegaLite spec={spec} actions={false}/>
    }
  </>;
};

export default PieChart;