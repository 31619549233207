import {GET, PATCH} from '@/serviceImpl/HttpFetch';
import {Profile} from '@/domain/entities/Profile';
import Clas, {ApiError, DashboardData, filteredParcelsType, filterGroupType, manifest} from '@/domain/services/Clas';
import {Parcel} from '@/domain/entities/Parcel';
import {getUrlWithParamsFromObject} from '@/domain/useCases/getUrlWithParamsFromObject';

const API_URL = process.env.BASE_URL;

const manifest = async (token: string) =>
  GET<Promise<manifest | ApiError>, {token: string}>(
    `${API_URL}/manifest/`,
    token
  );

const profile = async (token: string)=> 
  GET<Promise<Profile | ApiError>, {token: string}>(
    `${API_URL}/auth/users/me/`, 
    token
  );

const getParcelById = async (parcelId: number, token: string)=>
  GET<Promise<Parcel | ApiError>, {token: string}>(
    `${API_URL}/parcels/${parcelId}`,
    token
  );

const getDashboardData = async (userId: string, filterGroup: filterGroupType, token: string) =>
  GET<Promise<DashboardData | ApiError>, {token: string}>(
    getUrlWithParamsFromObject(`${API_URL}/dashboard/`, filterGroup),
    token
  );

const editParcel = async (newParcel: Parcel, token: string) =>
  PATCH<Promise<Parcel | ApiError>, Parcel>(
    `${API_URL}/parcels/${newParcel.id}`,
    newParcel,
    token
  );

const getFilteredParcels = async (filterGroup: filterGroupType, token: string) =>
  GET<Promise<filteredParcelsType | ApiError>, {filterGroup: filterGroupType, token: string}>(
    getUrlWithParamsFromObject(`${API_URL}/parcels/`, filterGroup),
    token
  );

const ClasImpl: Clas = {
  manifest,
  profile,
  getParcelById,
  editParcel,
  getDashboardData,
  getFilteredParcels
};

export default ClasImpl;